
const env = {
    API_URL: 'https://localhost:44369',
    CORS_URL: 'http://localhost:3000',
    PREVIEW: 'https://localhost:44319'
}
//Live
if (process.env.NODE_ENV === 'production') {
    env.API_URL = "https://service.cms.elvethamhotel.co.uk";
    env.CORS_URL = "https://site-manager.elvethamhotel.co.uk";
    env.PREVIEW = 'https://elvethamhotel.co.uk'
}
// if (process.env.NODE_ENV === 'production') {
//     env.API_URL = "https://service6.xeniaone.com";
//     env.CORS_URL = "https://app4.hostack.com";
//     env.PREVIEW = 'https://app2.hostack.com'
// }
// if (process.env.NODE_ENV === 'production') {
//     env.API_URL = "http://elvctstservice.hostack.com";
//     env.CORS_URL = "http://elvctst.hostack.com";
//     env.PREVIEW = 'http://elvwtst.hostack.com'
// }
export { env as ServiceUrls };